document.addEventListener("DOMContentLoaded", function () {
  const animateHeadings = document.querySelectorAll(".has-animated-keywords");

  animateHeadings.forEach((element) => {
    const period = ".";

    // Remove the period from the text node at the end of the heading, if present
    element.childNodes.forEach((node) => {
      if (
        node.nodeType === Node.TEXT_NODE &&
        node.nodeValue.trim().endsWith(period)
      ) {
        node.nodeValue = node.nodeValue.trim().slice(0, -1);
      }
    });

    // Check if the element contains a <mark> element; if not, skip the rest of the process
    const markElement = element.querySelector("mark");
    if (!markElement) return;

    // Extract and trim the text content within the <mark> element
    const textContent = markElement.textContent.trim();

    // Split the text content by commas into individual words and trim any extra spaces
    const keywords = textContent.split(",").map((keyword) => keyword.trim());

    // Wrap each keyword in a <span> with class "word" and reinsert them into the <mark> element
    markElement.innerHTML = keywords
      .map(
        (keyword, index) =>
          `<span class="word" data-index="${index}">${keyword}</span>`
      )
      .join("");

    // Append the period after the <mark> element if it doesn't already exist
    if (!element.querySelector(".period")) {
      markElement.insertAdjacentHTML(
        "afterend",
        `<span class="period">${period}</span>`
      );
    }

    // Get references to the wrapped words and the period element for animation
    const words = markElement.querySelectorAll(".word");
    const periodElement = element.querySelector(".period");
    let currentIndex = 0;

    // Function to handle the word switching animation
    function switchKeyword() {
      const nextIndex = (currentIndex + 1) % words.length;

      // Measure the width of the next word for period adjustment
      const newWordWidth = words[nextIndex].offsetWidth;

      // Create a timeline animation with GSAP
      gsap
        .timeline()
        .set(words[nextIndex], {
          y: 75,
          opacity: 0,
          visibility: "visible"
        }) // Position the next word 75px below and hide it initially
        .to(words[currentIndex], {
          y: -75,
          opacity: 0,
          duration: 0.6,
          ease: "power4.out"
        }) // Move the current word up and fade it out
        .to(
          words[nextIndex],
          {
            y: 0,
            opacity: 1,
            duration: 0.6,
            ease: "power4.out"
          },
          "-=0.6"
        ) // Simultaneously move the next word up and fade it in
        .to(
          periodElement,
          { x: newWordWidth, duration: 0.6, ease: "power4.out" },
          "-=0.6"
        ); // Move the period to align with the next word

      // Update the current index to the next word
      currentIndex = nextIndex;
    }

    // Initialize by hiding all words except the first and positioning them
    words.forEach((word, index) => {
      word.style.position = "absolute";
      word.style.top = 0;
      word.style.left = 0;
      word.style.visibility = index === 0 ? "visible" : "hidden";
      word.style.opacity = index === 0 ? 1 : 0;
    });

    // Set an interval to switch keywords every 1000ms
    setInterval(switchKeyword, 1250);
  });
});
