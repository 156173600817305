/* Umbrella Cursor */
document.addEventListener('DOMContentLoaded', () => {
    const customCursor = document.getElementById('umbrella-cursor'); // Get the custom cursor element
    const hoverItems = Array.from(document.querySelectorAll('.umbrella-link')); // Cache hover items as array
    const cursorOffset = 50; // Set the cursor offset

    // Detect if the device is touch-enabled
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    // If it's a touch device, don't initialize the custom cursor
    if (isTouchDevice) {
        customCursor.style.display = 'none'; // Hide the cursor on touch devices
        return; // Exit the script
    }

    let mouseX = 0; // Store the last known mouse X position
    let mouseY = 0; // Store the last known mouse Y position
    let cursorActive = false; // Boolean to track if the cursor should be shown

    // Mousemove event to update the custom cursor position
    const updateCursor = (e) => {
        mouseX = e.clientX; // Capture the current mouse X position
        mouseY = e.clientY; // Capture the current mouse Y position

        // Use transform to position the custom cursor based on the current mouse position
        customCursor.style.transform = `translate(${mouseX - cursorOffset}px, ${mouseY - cursorOffset}px)`;

        cursorActive = true; // Set the cursor to active
    };

    document.addEventListener('mousemove', updateCursor);

    // Handle hover on items with the "umbrella-link" class
    hoverItems.forEach(item => {
        if (item && typeof item.addEventListener === 'function') {
            item.addEventListener('mouseenter', () => {
                if (cursorActive) {
                    customCursor.style.visibility = 'visible';
                }
            });

            item.addEventListener('mouseleave', () => {
                customCursor.style.visibility = 'hidden'; // Hide the custom cursor when not hovering
            });
        }
    });
});