// Fade-In-Up animation
document.addEventListener("DOMContentLoaded", function () {
    // Animate elements with the class .has-fade-in-up
    const fadeInUpElements = document.querySelectorAll(".has-fade-in-up");
    if (fadeInUpElements.length > 0) {
        fadeInUpElements.forEach(function (element) {
            gsap.from(element, {
                scrollTrigger: {
                    trigger: element,
                    start: "top 85%", // Start the animation when the top of the element is 85% from the top of the viewport
                    toggleActions: "play none none none" // Play the animation once when the element comes into view
                },
                opacity: 0,
                y: 30, // Adjust the starting position
                duration: 1.5,
                ease: "power2.out",
            });
        });
    }
});