// Ensure webpack processes the main scss file.
import "../scss/main.scss";

// Register plugins
gsap.registerPlugin(ScrollTrigger, SplitText, ScrollSmoother);

// Import core scripts
import "./main/core/scrolling.js";
import "./main/core/accessibility.js";

// Import our layout scripts
import "./main/layout/header.js";

// Import UI scripts
import "./main/ui/buttons.js";
import "./main/ui/links.js";

// Import animation scripts
import "./main/animations/fade-in-up.js";
import "./main/animations/scroll-highlighter.js";
import "./main/animations/case-study-titles.js";
import "./main/animations/animated-keywords.js";

// Temp code to test different site styles
// jQuery(document).ready(function ($) {
//   $(document).on("keydown", function (e) {
//     if (e.key === "c") {
//       $("body").toggleClass("no-card-style");
//     }
//   });

//   $(document).on("keydown", function (e) {
//     if (e.key === "b") {
//       $("body").toggleClass("no-border-radius");
//     }
//   });
// });
