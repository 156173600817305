/**
 * Script for managing animations, logo and navigation inversion, and dynamic menu toggling.
 *
 * Features:
 * - Inverts logo and navigation colours based on intersections with specific background sections.
 * - Animates logo elements and navigation visibility based on scroll position or menu activity.
 * - Handles the opening and closing of pull menus and search forms with GSAP animations.
 *
 * Usage:
 * - This script initializes on DOMContentLoaded and attaches event listeners for user interactions
 *   (clicks, scrolls, resizes).
 *
 * Dependencies:
 * - GSAP (for animations)
 */

document.addEventListener("DOMContentLoaded", function () {
  const elements = {
    body: document.body,
    logoSvg: document.getElementById("logo-svg"),
    logoLetter: document.getElementById("logo-letter"),
    logoWord: document.getElementById("logo-word"),
    logoDot: document.getElementById("logo-dot"),
    navigation: document.getElementById("navigation"),
    mainMenu: document.getElementById("main-menu"),
    pullMenu: document.getElementById("pull-menu"),
    pullIcon: document.getElementById("pull-icon"),
    searchIcon: document.getElementById("search-icon"),
    searchForm: document.getElementById("search-form"),
    targetClasses: [
      ...document.querySelectorAll(
        ".has-evening-inspo-background-color, .has-max-boost-background-color, .has-black-background-color, .force-color-inversion",
      ),
    ],
  };

  // Function to toggle a class based on a condition
  const toggleClass = (element, className, condition) => {
    if (condition) {
      element.classList.add(className);
    } else {
      element.classList.remove(className);
    }
  };

  // Function to handle animations for logo and navigation
  const handleAnimations = () => {
    const isMenuActive = elements.pullMenu.classList.contains("active");
    const isSearchFormActive = elements.searchForm.classList.contains("active");
    const shouldAnimate =
      window.scrollY > 15 || isMenuActive || isSearchFormActive;

    // Apply GSAP animations to the logo
    gsap.to(elements.logoWord, {
      autoAlpha: shouldAnimate ? 0 : 1,
      duration: 0.2,
    });

    gsap.to(elements.logoDot, {
      x: shouldAnimate ? -360 : 0,
      duration: 0.2,
      ease: "power1.out",
    });

    gsap.to(elements.logoSvg, {
      scale: shouldAnimate ? 1.33 : 1,
      duration: 0.2,
      ease: "power1.out",
    });

    gsap.to(elements.mainMenu, {
      autoAlpha: shouldAnimate ? 0 : 1,
      duration: 0.2,
    });
  };

  // Function to check if two elements intersect both vertically and horizontally
  const isIntersecting = (rect1, rect2) => {
    return (
      rect1.bottom > rect2.top && // vertically overlapping
      rect1.top < rect2.bottom && // vertically overlapping
      rect1.right > rect2.left && // horizontally overlapping
      rect1.left < rect2.right // horizontally overlapping
    );
  };

  // Function to handle logo inversion based on intersection with target background classes
  const handleLogoInversion = () => {
    const logoRect = elements.logoSvg.getBoundingClientRect();
    const intersectsTargetClass = elements.targetClasses.some((target) => {
      const targetRect = target.getBoundingClientRect();
      return isIntersecting(logoRect, targetRect);
    });

    // Toggle the invert-color class on the logo elements
    toggleClass(elements.logoSvg, "invert-color", intersectsTargetClass);
  };

  // Function to handle navigation inversion based on intersection with target background classes
  const handleNavigationInversion = () => {
    const navigationRect = elements.navigation.getBoundingClientRect();
    const intersectsNavTargetClass = elements.targetClasses.some((target) => {
      const targetRect = target.getBoundingClientRect();
      return isIntersecting(navigationRect, targetRect);
    });

    // Toggle the invert-color class on navigation elements
    [elements.mainMenu, elements.pullIcon, elements.searchIcon].forEach(
      (element) => {
        toggleClass(element, "invert-color", intersectsNavTargetClass);
      },
    );
  };

  // Unified handler for inversions and animations
  const handleAnimationsAndInversions = () => {
    handleLogoInversion(); // Handle logo inversion based purely on intersection
    handleNavigationInversion(); // Handle navigation inversion based purely on intersection
    handleAnimations(); // Handle animations based on scroll and menu states
  };

  // Function to toggle an element with GSAP animations
  const toggleElementWithGSAP = (element, open) => {
    if (open) {
      element.style.display = "flex";
      gsap.to(element, {
        opacity: 1,
        y: 0,
        duration: 0.2,
        onComplete: handleAnimationsAndInversions,
      });
    } else {
      gsap.to(element, {
        opacity: 0,
        y: "-100%",
        duration: 0.2,
        onComplete: () => {
          element.style.display = "none";
          handleAnimationsAndInversions();
        },
      });
    }
  };

  // Toggle pull menu and update animations and inversions
  elements.pullIcon.addEventListener("click", () => {
    const isMenuActive = elements.pullIcon.classList.contains("active");
    toggleClass(elements.pullMenu, "active", !isMenuActive);
    toggleClass(elements.pullIcon, "active", !isMenuActive);
    toggleClass(elements.body, "no-scroll", !isMenuActive);
    toggleElementWithGSAP(elements.pullMenu, !isMenuActive); // Close the menu if it's open
  });

  // Toggle search form and update animations and inversions
  elements.searchIcon.addEventListener("click", () => {
    const isSearchFormActive = elements.searchIcon.classList.contains("active");
    toggleClass(elements.searchForm, "active", !isSearchFormActive);
    toggleClass(elements.searchIcon, "active", !isSearchFormActive);
    toggleClass(elements.body, "no-scroll", !isSearchFormActive);
    toggleElementWithGSAP(elements.searchForm, !isSearchFormActive);
  });

  // Attach event listeners for scroll and resize
  window.addEventListener("scroll", handleAnimationsAndInversions);
  window.addEventListener("resize", handleAnimationsAndInversions);

  // Initial call to set the correct state based on the current viewport and scroll position
  handleAnimationsAndInversions();
});
