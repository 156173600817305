/* Buttons */
document.addEventListener("DOMContentLoaded", function () {
    const buttons = document.querySelectorAll(".wp-block-button");

    buttons.forEach((button) => {
        const link = button.querySelector(".wp-block-button__link");

        // Read the dimensions of the button BEFORE clearing the innerHTML
        const rect = button.getBoundingClientRect();
        link.style.width = `${rect.width}px`;
        link.style.height = `${rect.height}px`;

        const text = document.createElement("span");
        text.className = "movable-text";
        text.innerHTML = link.innerHTML;

        // Clear the link and append the span
        link.innerHTML = "";
        link.appendChild(text);

        button.addEventListener("mousemove", function (e) {
            // Moved inside the event listener to keep it current.
            const rect = button.getBoundingClientRect();

            const x = e.clientX - rect.left - rect.width / 2;
            const y = e.clientY - rect.top - rect.height / 2;

            const textMoveX = Math.max(Math.min(x, 3), -3);
            const textMoveY = Math.max(Math.min(y, 3), -3);

            const buttonMoveX = textMoveX / 2;
            const buttonMoveY = textMoveY / 2;

            text.style.setProperty('transform', `translate(${textMoveX}px, ${textMoveY}px)`, 'important');
            button.style.setProperty('transform', `translate(${buttonMoveX}px, ${buttonMoveY}px)`, 'important');
        });

        button.addEventListener("mouseleave", function () {
            text.style.setProperty('transform', 'translate(0, 0)', 'important');
            button.style.setProperty('transform', 'translate(0, 0)', 'important');
        });
    });
});









