// Fade and scale the case study title on scroll.
document.addEventListener('DOMContentLoaded', () => {
    const title = document.querySelector('.case-study-title');
    if (!title) return;

    const startScalingDistance = 20,
        maxScaleDown = 0.6,
        fadeStart = 20,
        fadeDistance = 350,
        maxTranslateY = 200;

    ScrollTrigger.create({
        start: 0,
        onUpdate: (self) => {
            const scrollY = self.scroll(),
                distanceFromTop = Math.max(scrollY - startScalingDistance, 0),
                fadeProgress = Math.max(scrollY - fadeStart, 0) / fadeDistance,
                scale = Math.max(1 - (distanceFromTop / 1000), maxScaleDown),
                opacity = Math.max(1 - fadeProgress, 0),
                translateY = Math.min(fadeProgress * maxTranslateY, maxTranslateY);

            gsap.to(title, {scale, opacity, y: translateY, ease: "none", transformOrigin: "center bottom"});
        }
    });
});

